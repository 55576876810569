import React from "react"
import headerImage from '../assets/LAN_01.jpeg'

import { withTranslation } from 'react-i18next';

function Entry({ t }) {
  return (
    <div className="container-fluid entry-main" id="entry">
      <img src={headerImage} className="header-image" />
    </div>
  )
}

export default withTranslation()(Entry)
