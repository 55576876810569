import React, { Component } from 'react';
import saleSheet from '../assets/scones_salesheet.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

class Footer extends Component {

  render() {
    const { t } = this.props;
    return (
      <footer className="font-small pt-4 footer pb-2">
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-md-4 mt-md-0 mt-3">
              <h4 id="contact-us" className="footer-header text-uppercase font-weight-bold">{t('footer.contactUs.title')}</h4>
              <div>
                5833 Sherbrooke O. <br/>
                Montréal (QC) <br/>
                H4A 1X4
              </div>
              <div className="mt-3">
                <span className="pr-3">
                  <a href="https://www.facebook.com/Patisserie-Gryphon-Bakery-2121028294842336" target="_blank" rel="noopener noreferrer"className="colorless-anchor">
                    <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
                  </a>
                </span>
              </div>
              <div className="mt-3">
                <strong>Tel.</strong> (514) 487-3933 <br/>
                <strong>Email:</strong> info@gryphonbakery.com
              </div>
              <div className="mt-3"><a href="http://wholesale.gryphonbakery.com" target="_blank">{t('footer.contactUs.wholesaleWebsite')}</a></div>
            </div>
            <div className="col-md-4 mt-md-0">
              <h4 className="footer-header text-uppercase font-weight-bold">{t('footer.openingHours.title')}</h4>
              <div className="float-left">{t('footer.openingHours.monday')}</div>
              <div className="float-right">{t('footer.openingHours.closed')}</div><br/>
              <div className="float-left">{t('footer.openingHours.tuesday')}</div>
              <div className="float-right">10:00 - 18:00</div><br/>
              <div className="float-left">{t('footer.openingHours.wednesday')}</div>
              <div className="float-right">10:00 - 18:00</div><br/>
              <div className="float-left">{t('footer.openingHours.thursday')}</div>
              <div className="float-right">10:00 - 18:00</div><br/>
              <div className="float-left">{t('footer.openingHours.friday')}</div>
              <div className="float-right">10:00 - 18:00</div><br/>
              <div className="float-left">{t('footer.openingHours.saturday')}</div>
              <div className="float-right">10:00 - 17:00</div><br/>
              <div className="float-left">{t('footer.openingHours.sunday')}</div>
              <div className="float-right">{t('footer.openingHours.closed')}</div>
            </div>
            <div className="col-md-4 mt-md-0 mt-3 mb-3">
              <h4 className="footer-header text-uppercase font-weight-bold">{t('footer.whereToFindUs')}</h4>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.0060749051595!2d-73.62011558423306!3d45.46968154109836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc910b1532ff3b7%3A0xeb5a15ab44258fda!2s5833%20Sherbrooke%20St%20W%2C%20Montreal%2C%20QC%20H4A%201X4!5e0!3m2!1sen!2sca!4v1628729847773!5m2!1sen!2sca" 
                  height="250"
                  frameborder="0" style={{border: 0, position: "relative", width: "100%"}}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0">
                </iframe>
              </div>
              <div className="mt-1"><a href="https://www.vecteezy.com/free-vector/celtic-knot">Celtic Knot Vectors by Vecteezy</a></div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(Footer)