import React from "react"
import scone1 from '../assets/SCN_01.jpeg'
import scone2 from '../assets/SCN_02.jpeg'
import scone3 from '../assets/SCN_03.jpeg'
import scone4 from '../assets/SCN_04.jpeg'
import scone5 from '../assets/SCN_05.jpeg'
import shortbread1 from '../assets/SBD_01.jpeg'
import shortbread2 from '../assets/SBD_02.jpeg'
import loaves1 from '../assets/LVS_01.jpeg'
import loaves2 from '../assets/LVS_02.jpeg'
import loaves3 from '../assets/LVS_03.jpeg'
import loaves4 from '../assets/LVS_04.jpeg'
import loaves5 from '../assets/LVS_05.jpeg'
import loaves6 from '../assets/LVS_06.jpeg'
import pies1 from '../assets/PIE_01.jpeg'
import pies2 from '../assets/PIE_02.jpeg'
import sweetTreats1 from '../assets/SWT_01.jpeg'
import sweetTreats2 from '../assets/SWT_02.jpeg'
import sweetTreats3 from '../assets/SWT_03.jpeg'
import sweetTreats4 from '../assets/SWT_04.jpeg'
import sweetTreats5 from '../assets/SWT_05.jpeg'
import sweetTreats6 from '../assets/SWT_06.jpeg'
import sweetTreats7 from '../assets/SWT_07.jpeg'
import sweetTreats8 from '../assets/SWT_08.jpeg'
import spreadAndJams1 from '../assets/SAJ_01.jpeg'
import spreadAndJams2 from '../assets/SAJ_02.jpeg'
import spreadAndJams3 from '../assets/SAJ_03.jpeg'
import spreadAndJams4 from '../assets/SAJ_04.jpeg'
import spreadAndJams5 from '../assets/SAJ_05.jpeg'
import spreadAndJams6 from '../assets/SAJ_06.jpeg'
import savoury1 from '../assets/SAV_01.jpeg'
import savoury2 from '../assets/SAV_02.jpeg'
import savoury3 from '../assets/SAV_03.jpeg'
import speciality1 from '../assets/SPC_01.jpeg'
import speciality2 from '../assets/SPC_02.jpeg'
import speciality3 from '../assets/SPC_03.jpeg'
import speciality4 from '../assets/SPC_04.jpeg'
import comingSoon from '../assets/Coming_Soon.png'

import { withTranslation } from 'react-i18next';
import Heading from "./Heading"
import ProductBox from "./ProductBox"

function Product({ t }) {
  return (
    <div className="container-fluid pb-3">
      <Heading
        title={t('header.products')}
        id="product"
      />
      <div className="text-center mb-3">{t('product.disclaimer')}</div>
      <div className="row">
        <ProductBox
          title={t('product.scones.title')}
          id="carousel-scones"
          modalId="modal-carousel-scones"
          modalContent={t('product.scones.modalContent', {returnObjects: true})}
          descriptions={t('product.scones.descriptions', {returnObjects: true})}
          items={[scone1, scone2, scone3, scone4, scone5]}
        />
        <ProductBox
          title={t('product.shortbreads.title')}
          id="carousel-shortbread"
          modalId="modal-carousel-shortbread"
          modalContent={t('product.shortbreads.modalContent', {returnObjects: true})}
          descriptions={t('product.shortbreads.descriptions', {returnObjects: true})}
          items={[shortbread1, shortbread2]}
        />
        <ProductBox
          title={t('product.loaves.title')}
          id="carousel-loaves"
          modalId="modal-carousel-loaves"
          modalContent={t('product.loaves.modalContent', {returnObjects: true})}
          descriptions={t('product.loaves.descriptions', {returnObjects: true})}
          items={[loaves1, loaves2, loaves3, loaves4, loaves5, loaves6]}
        />
        <ProductBox
          title={t('product.pies.title')}
          id="carousel-pies"
          modalId="modal-carousel-pies"
          modalContent={t('product.pies.modalContent', {returnObjects: true})}
          descriptions={t('product.pies.descriptions', {returnObjects: true})}
          items={[pies1, pies2]}
        />
        <ProductBox
          title={t('product.sweetTreats.title')}
          id="carousel-sweetTreats"
          modalId="modal-carousel-sweetTreats"
          modalContent={t('product.sweetTreats.modalContent', {returnObjects: true})}
          descriptions={t('product.sweetTreats.descriptions', {returnObjects: true})}
          items={[sweetTreats1, sweetTreats2, sweetTreats3, sweetTreats4, sweetTreats5, sweetTreats6, sweetTreats7, sweetTreats8]}
        />
        <ProductBox
          title={t('product.spreadAndJams.title')}
          id="carousel-spreadAndJams"
          modalId="modal-carousel-spreadAndJams"
          modalContent={t('product.spreadAndJams.modalContent', {returnObjects: true})}
          descriptions={t('product.spreadAndJams.descriptions', {returnObjects: true})}
          items={[spreadAndJams1, spreadAndJams2, spreadAndJams3, spreadAndJams4, spreadAndJams5, spreadAndJams6]}
        />
        <ProductBox
          title={t('product.savoury.title')}
          id="carousel-savoury"
          modalId="modal-carousel-savoury"
          modalContent={t('product.savoury.modalContent', {returnObjects: true})}
          descriptions={t('product.savoury.descriptions', {returnObjects: true})}
          items={[savoury1, savoury2, savoury3]}
        />
        <ProductBox
          title={t('product.seasonal.title')}
          id="carousel-seasonal"
          modalId="modal-carousel-seasonal"
          modalContent={t('product.seasonal.modalContent', {returnObjects: true})}
          descriptions={t('product.seasonal.descriptions', {returnObjects: true})}
          items={[speciality1, speciality2, speciality3, speciality4]}
        />
      </div>
    </div>
  )
}

export default withTranslation()(Product)
