import React from "react"

function Heading(props) {
  return (
    <div className="container-fluid" id={props.id}>
      <h1 className="text-uppercase text-center heading">
        {props.title}
      </h1>
    </div>
  )
}

export default Heading
