import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Navbar from "./components/Navbar"
import Main from "./components/Main"
import GenericNotFound from "./components/GenericNotFound"
import Footer from "./components/Footer"

library.add(fab)

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="*" component={GenericNotFound} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
