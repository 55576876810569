import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ProductBox(props) {
  let navigation = <div></div>
  if (props.items.length > 1) {
    navigation = <div>
      <a className="carousel-control-prev" href={"#" + props.id} role="button" data-slide="prev">
          <span className="carousel-control-prev-icon outline-arrow" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href={"#" + props.id} role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
      </a>
    </div>
  }
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div className>
        <div className="text-center mt-4 mb-2">
          <h3>{props.title}</h3>
          <div id={props.id} className="carousel slide" data-ride="carousel" data-interval="false">
            <div className="carousel-inner carousel-background pointer border border-dark"  data-toggle="modal" data-target={"#" + props.modalId} >
              {
                  props.items.map((item, index) =>
                  <div className={(index === 0) ? "carousel-item active" : "carousel-item" }>
                      <img src={item} className="d-block h-100 custom-image" alt="..."/>
                  </div>
                  )
              }
            </div>
            {navigation}
          </div>
        </div>
      </div>
      <div class="modal fade" id={props.modalId} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLongTitle">{props.title}</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              {
                props.descriptions.map((description) =>
                  <div className="mb-3">{description}</div>
                )
              }
              {
                  props.modalContent.map((item) =>
                    <div className="mb-3">
                      {item.title.length > 0 && <h5>{item.title}</h5>}
                      {
                        item.sections.map((section) =>
                          <div className="mb-1">
                            {section.title.length > 0 && <div className="font-weight-bolder">{section.title}</div>}
                            {
                              section.items.map((entry) =>
                                <div>{entry}</div>
                              )
                            }
                          </div>
                        )
                      }      
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductBox
