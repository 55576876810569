import React from "react"

import Entry from "./Entry"
import AboutUs from "./AboutUs"
import Product from "./Product"
import Banner from "./Banner"
import ProductSingle from "./Product"
import ContactUs from "./ContactUs"

function Main() {
  return (
    <div>
      <section>
        <Entry />
      </section>
      <section>
        <AboutUs />
      </section>
      <section>
        <Product />
      </section>
    </div>
  )
}

export default Main