import React from "react"

import { withTranslation } from 'react-i18next';

function Banner({ t }) {
  return (
    <div className="container-fluid banner pt-2 pb-2">
        {t('banner.message')}
    </div>
  )
}

export default withTranslation()(Banner)
