import React from "react"
import logoImage from '../assets/ABT_01.png'
import { withTranslation } from 'react-i18next';
import Heading from "./Heading"

function AboutUs({ t }) {
  return (
    <div className="container-fluid">
      <Heading
        title={t('header.aboutUs')}
        id="about-us"
      />
      <div className="row">
        <div className="col-lg-6">
          <div>{t('aboutUs.section1')}</div><br/>
          <div>{t('aboutUs.section2')}</div>
        </div>
        <div className="col-lg-6">
          <div className="center-text p-3">
            <div className="col d-flex align-items-center justify-content-center">
              <img className="d-block m-auto image-max" src={logoImage} alt="Owner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(AboutUs)
